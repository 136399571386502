// import pageTransitions from './animations/transitions';
// import * as basicScroll from 'basicscroll'
import utils from "./utils";
import nav from "./nav";
import sliders from "./sliders";

import Aoe from "aoejs";

export default class App {
  constructor() {
    this.initDev(); // tests and shit
    this.initBody(); // runs on initialize & after barba
  }

  initBody() {
    sliders();
    const aoe = new Aoe();
    aoe.init({});
  }

  initCore() {
    // pageTransitions(); // barbaajs not needed, single page
    nav.hamburger();
    console.log("core");
    this.initBody();
    console.log("body");
  }

  initDev() {
    utils.clone("[data-clone]");
  }
}

function LoadApp() {
  const app = new App();
}

if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", LoadApp);
} else {
  LoadApp();
}
var imgs = document.images,
  len = imgs.length,
  counter = 0;

[].forEach.call(imgs, function(img) {
  if (img.complete) incrementCounter();
  else img.addEventListener("load", incrementCounter, false);
});

function incrementCounter() {
  console.log(imgs[counter].parentNode.parentNode.classList.add("loaded"));
  counter++;

  if (counter === len) {
    console.log("All images loaded!");
  }
}
